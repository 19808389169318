:root {
  --light-bg: #e4ebf5;
  --light-bg-dark-shadow: #bec8e4;
  --light-bg-light-shadow: #ffffff;

  --dark-bg: #444444;
  --dark-bg-dark-shadow: #363636;
  --dark-bg-light-shadow: #525252;

  --white: #ffffff;
  --black: #000000;

  --primary: #EF7F1A;
  --primary-light: #ff7700;
  --primary-dark: #ffb575;

  --error: #ff5252;
  --info: #2196f3;
  --success: #4caf50;
  --warning: #dfa700;

  --g-text-color-light: rgba(0, 0, 0, 0.93);
  --g-text-color-disabled-light: rgb(126, 126, 126);
  --g-text-color-secondary-light: rgb(0, 0, 0, 0.7);

  --g-text-color-dark: rgba(255, 255, 255, 0.93);
  --g-text-color-disabled-dark: rgb(126, 126, 126);
  --g-text-color-secondary-dark: rgba(255, 255, 255, 0.7);

  --g-bg-color-disabled-light: #dee5e8;
  --g-bg-color-disabled-dark: #727272;

  --code-bg: #272822;
  --logo-img: var(--dark-logo-img);
  --bg-color: var(--dark-bg);
  --text-color: var(--g-text-color-secondary-dark);
  --text-color-active: var(--primary-dark);
  --text-shadow: var(--dark-bg-dark-shadow);
  --menu-text-color: var(--g-text-color-dark);
  --menu-text-hover-color: var(--primary-light);
  --text-secondary: var(--g-text-color-disabled-dark);
  --box-shadow: 5px 5px 12px var(--dark-bg-dark-shadow),
    -4px -4px 10px var(--dark-bg-light-shadow);
  --box-shadow-hover: 2px 2px 5px var(--dark-bg-dark-shadow),
    -2px -2px 5px var(--dark-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--dark-bg-light-shadow),
    inset 2px 2px 5px var(--dark-bg-dark-shadow);
  --box-shadow-inset-low: inset -1px -1px 3px var(--dark-bg-light-shadow),
    inset 1px 1px 3px var(--dark-bg-dark-shadow);
  --box-shadow-inset-strong: inset -4px -4px 8px var(--dark-bg-light-shadow),
    inset 4px 4px 8px var(--dark-bg-dark-shadow);
  --box-shadow-fab: 10px 10px 20px var(--dark-bg-dark-shadow),
    -7px -7px 16px var(--dark-bg-light-shadow);
}
* {
  margin: 0;
  padding: 0;
}

.theme--dark {
  --code-bg: #f5f2f0;
  --logo-img: var(--light-logo-img);
  --bg-color: var(--light-bg);
  --text-color: var(--g-text-color-secondary-light);
  --text-color-active: var(--primary-light);
  --text-shadow: var(--light-bg-light-shadow);
  --menu-text-color: var(--g-text-color-light);
  --menu-text-hover-color: var(--primary-dark);
  --text-secondary: var(--g-text-color-disabled-light);
  --box-shadow: 5px 5px 12px var(--light-bg-dark-shadow),
    -4px -4px 10px var(--light-bg-light-shadow);
  --box-shadow-hover: 2px 2px 5px var(--light-bg-dark-shadow),
    -2px -2px 5px var(--light-bg-light-shadow);
  --box-shadow-inset: inset -2px -2px 5px var(--light-bg-light-shadow),
    inset 2px 2px 5px var(--light-bg-dark-shadow);
  --box-shadow-inset-low: inset -1px -1px 3px var(--light-bg-light-shadow),
    inset 1px 1px 3px var(--light-bg-dark-shadow);
  --box-shadow-inset-strong: inset -4px -4px 8px var(--light-bg-light-shadow),
    inset 4px 4px 8px var(--light-bg-dark-shadow);
  --box-shadow-fab: 10px 10px 20px var(--light-bg-dark-shadow),
    -7px -7px 16px var(--light-bg-light-shadow);

}

pre {
  margin: 0px !important;
  border-radius: 8px !important;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: var(--bg-color);
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:link{
  color: var(--text-color);
  text-decoration: none;
}

a:visited{
  color: var(--text-color);
  text-decoration: none;
}
a:hover{
  color: var(--text-color);
  text-decoration: none;
}
a:active{
  color: var(--text-color-active);
  text-decoration: none;
}

/* global */

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
}

.ma-0 {
  margin: 0px !important;
}

.ma-1 {
  margin: 4px !important;
}

.ma-2 {
  margin: 8px !important;
}

.ma-3 {
  margin: 12px !important;
}

.ma-4 {
  margin: 16px !important;
}

.ma-5 {
  margin: 20px !important;
}

.ma-6 {
  margin: 24px !important;
}

.ma-7 {
  margin: 28px !important;
}

.ma-8 {
  margin: 32px !important;
}

.ma-9 {
  margin: 36px !important;
}

.ma-10 {
  margin: 40px !important;
}

.ma-11 {
  margin: 44px !important;
}

.ma-12 {
  margin: 48px !important;
}

.ma-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.mx-3 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.mx-4 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.mx-6 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.mx-7 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.mx-8 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.mx-9 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.mx-10 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.mx-11 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.mx-12 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-6 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-7 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.my-8 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-9 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.my-10 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-11 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.my-12 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 24px !important;
}

.mt-7 {
  margin-top: 28px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-9 {
  margin-top: 36px !important;
}

.mt-10 {
  margin-top: 40px !important;
}

.mt-11 {
  margin-top: 44px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 4px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.mr-3 {
  margin-right: 12px !important;
}

.mr-4 {
  margin-right: 16px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-6 {
  margin-right: 24px !important;
}

.mr-7 {
  margin-right: 28px !important;
}

.mr-8 {
  margin-right: 32px !important;
}

.mr-9 {
  margin-right: 36px !important;
}

.mr-10 {
  margin-right: 40px !important;
}

.mr-11 {
  margin-right: 44px !important;
}

.mr-12 {
  margin-right: 48px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 24px !important;
}

.mb-7 {
  margin-bottom: 28px !important;
}

.mb-8 {
  margin-bottom: 32px !important;
}

.mb-9 {
  margin-bottom: 36px !important;
}

.mb-10 {
  margin-bottom: 40px !important;
}

.mb-11 {
  margin-bottom: 44px !important;
}

.mb-12 {
  margin-bottom: 48px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 4px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-3 {
  margin-left: 12px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-6 {
  margin-left: 24px !important;
}

.ml-7 {
  margin-left: 28px !important;
}

.ml-8 {
  margin-left: 32px !important;
}

.ml-9 {
  margin-left: 36px !important;
}

.ml-10 {
  margin-left: 40px !important;
}

.ml-11 {
  margin-left: 44px !important;
}

.ml-12 {
  margin-left: 48px !important;
}

.pa-0 {
  padding: 0px !important;
}

.pa-1 {
  padding: 4px !important;
}

.pa-2 {
  padding: 8px !important;
}

.pa-3 {
  padding: 12px !important;
}

.pa-4 {
  padding: 16px !important;
}

.pa-5 {
  padding: 20px !important;
}

.pa-6 {
  padding: 24px !important;
}

.pa-7 {
  padding: 28px !important;
}

.pa-8 {
  padding: 32px !important;
}

.pa-9 {
  padding: 36px !important;
}

.pa-10 {
  padding: 40px !important;
}

.pa-11 {
  padding: 44px !important;
}

.pa-12 {
  padding: 48px !important;
}

.px-0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.px-5 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.px-6 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.px-7 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.px-8 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.px-9 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.px-10 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.px-11 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.px-12 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-6 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-7 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.py-8 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-9 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.py-10 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-11 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.py-12 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 4px !important;
}

.pt-2 {
  padding-top: 8px !important;
}

.pt-3 {
  padding-top: 12px !important;
}

.pt-4 {
  padding-top: 16px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-6 {
  padding-top: 24px !important;
}

.pt-7 {
  padding-top: 28px !important;
}

.pt-8 {
  padding-top: 32px !important;
}

.pt-9 {
  padding-top: 36px !important;
}

.pt-10 {
  padding-top: 40px !important;
}

.pt-11 {
  padding-top: 44px !important;
}

.pt-12 {
  padding-top: 48px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 4px !important;
}

.pr-2 {
  padding-right: 8px !important;
}

.pr-3 {
  padding-right: 12px !important;
}

.pr-4 {
  padding-right: 16px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 24px !important;
}

.pr-7 {
  padding-right: 28px !important;
}

.pr-8 {
  padding-right: 32px !important;
}

.pr-9 {
  padding-right: 36px !important;
}

.pr-10 {
  padding-right: 40px !important;
}

.pr-11 {
  padding-right: 44px !important;
}

.pr-12 {
  padding-right: 48px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 4px !important;
}

.pb-2 {
  padding-bottom: 8px !important;
}

.pb-3 {
  padding-bottom: 12px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-6 {
  padding-bottom: 24px !important;
}

.pb-7 {
  padding-bottom: 28px !important;
}

.pb-8 {
  padding-bottom: 32px !important;
}

.pb-9 {
  padding-bottom: 36px !important;
}

.pb-10 {
  padding-bottom: 40px !important;
}

.pb-11 {
  padding-bottom: 44px !important;
}

.pb-12 {
  padding-bottom: 48px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 4px !important;
}

.pl-2 {
  padding-left: 8px !important;
}

.pl-3 {
  padding-left: 12px !important;
}

.pl-4 {
  padding-left: 16px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 24px !important;
}

.pl-7 {
  padding-left: 28px !important;
}

.pl-8 {
  padding-left: 32px !important;
}

.pl-9 {
  padding-left: 36px !important;
}

.pl-10 {
  padding-left: 40px !important;
}

.pl-11 {
  padding-left: 44px !important;
}

.pl-12 {
  padding-left: 48px !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.float-none {
  float: none !important;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-space-around {
  justify-content: space-around !important;
}

.align-start {
  align-items: flex-start !important;
}

.align-end {
  align-items: flex-end !important;
}

.align-center {
  align-items: center !important;
}

.align-baseline {
  align-items: baseline !important;
}

.align-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-space-between {
  align-content: space-between !important;
}

.align-content-space-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.text-center {
  text-align: center;
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100% !important;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.c-pointer {
  cursor: pointer;
}

.top {
  background-color: var(--bg-color);
  position: fixed;
  top: 0;
  width: 100%;
}

.topBar {
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topBar-links {
  display: flex;
  align-items: center;
}

.divider {
  border: 0;
  height: 7px;
  border-radius: 10px;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset);
}

.divider-bottom {
  border: 0;
  height: 7px;
  border-radius: 10px;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset);
  display: none;
}

.bottom {
  background-color: var(--bg-color);
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0%;
}

.bottom-bar {
  display: none;
  justify-content: space-evenly;
}

.footer {
  font-size: 10px;
  font-weight: 700;
  line-height: 22px;
  text-transform: uppercase;
  align-items: center;
}

.footer p{
  color: var(--text-color);
  text-align: center;
}



.topBar-logo {
  max-width: 50px;
  max-height: 30px;
  user-select: none;
}

.topBar h2{
  font-size: 1.5rem;
  font-weight: 900;
  color: var(--text-color);
  font-style: italic;
  user-select: none;
}

.btn {
  outline: none !important;
  font-weight: 800;
  min-width: 64px;
  padding: 10px 20px;
  user-select: none;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color);
  height: 36px;
  box-shadow: var(--box-shadow);
  background-color: var(--bg-color);
  transition: box-shadow 200ms ease-in-out;
  -webkit-tap-highlight-color: transparent !important;
}

.btn-wallet {
  outline: none !important;
  font-weight: 700;
  min-width: 54px;
  padding: 0px;
  user-select: none;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color);
  height: 36px;
  cursor: pointer;
  box-shadow: var(--box-shadow-hover);
  background-color: var(--text);
  transition: box-shadow 200ms ease-in-out;
  -webkit-tap-highlight-color: transparent !important;
}

.btn-contract {
  outline: none !important;
  font-weight: 700;
  min-width: 54px;
  padding: 0px;
  user-select: none;
  border-radius: 6px;
  align-items: center;
  display: inline-flex;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color);
  height: 36px;
  cursor: pointer;
  box-shadow: var(--box-shadow-hover);
  background-color: var(--text);
  transition: box-shadow 200ms ease-in-out;
  -webkit-tap-highlight-color: transparent !important;
}

.btn-rounded {
  border-radius: 56px !important;
}

.btn-icon {
  outline: none !important;
  padding: 4px;
  user-select: none;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color);
  height: 30px;
  box-shadow: var(--box-shadow-hover);
  background-color: var(--bg-color);
  transition: box-shadow 200ms ease-in-out;
}

.btn-icon-active {
  box-shadow: var(--box-shadow-inset-low) !important;
  color: var(--text-color-active) !important;
}

.btn-toggle {
  outline: none !important;
  padding: 8px;
  user-select: none;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color);
  height: 36px;
  background-color: var(--bg-color);
  box-shadow: var(--box-shadow-hover);
  cursor: pointer;
}

.btn-toggle-active {
  outline: none !important;
  padding: 8px;
  user-select: none;
  border-radius: 4px;
  align-items: center;
  display: inline-flex;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  color: var(--text-color-active);
  height: 36px;
  background-color: var(--bg-color);
  box-shadow: var(--box-shadow-inset-low);
  cursor: pointer;
}

.btn-toggle:active {
  box-shadow: var(--box-shadow-inset) !important;
  color: var(--text-color-active) !important;
}

.bulb {
  color: var(--text-color);
}

.btn:hover {
  box-shadow: var(--box-shadow-hover) !important;
}

.btn:active {
  box-shadow: var(--box-shadow-inset-low) !important;
  color: var(--text-color-active) !important;
}

.btn-wallet:active {
  box-shadow: var(--box-shadow-inset-low) !important;
  color: var(--text-color-active) !important;
}

.btn-contract:active {
  box-shadow: var(--box-shadow-inset-low) !important;
  color: var(--text-color-active) !important;
}

.btn-icon:active {
  box-shadow: var(--box-shadow-inset-low) !important;
  color: var(--text-color-active) !important;
}

.chip {
  outline: none;
  padding: 10px 10px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 56px;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  text-decoration: none;
  vertical-align: middle;
  color: var(--text-color-active);
  transition-duration: 0.3s;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset-low);
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, opacity;
}

.chip-hilite {
  outline: none;
  padding: 5px 10px;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  align-items: center;
  white-space: nowrap;
  display: inline-flex;
  text-decoration: none;
  vertical-align: middle;
  color: var(--text-color-active);
  transition-duration: 0.3s;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset);
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, opacity;
}

.chip-input {
  outline: none;
  padding: 8px 8px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  white-space: nowrap;
  display: inline-flex;
  text-decoration: none;
  transition-duration: 0.3s;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset-strong);
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, opacity;
}

.chip-input input{
  width: 3rem;
  height: 2rem;
  outline: none;
  border: none;
  text-align: center;
  color: var(--text-color-active);
  background-color: transparent;
  vertical-align: middle;
  border: none;
}

.chip-input-new {
  outline: none;
  padding: 8px 8px;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  white-space: nowrap;
  display: inline-flex;
  text-decoration: none;
  transition-duration: 0.3s;
  background: var(--bg-color);
  box-shadow: var(--box-shadow-inset-strong);
  transition-timing-function: ease-in-out;
  transition-property: box-shadow, opacity;
}

.chip-input-new input{
  width: 8rem;
  height: 1.5rem;
  outline: none;
  border: none;
  text-align: center;
  color: var(--text-color-active);
  background-color: transparent;
  vertical-align: middle;
  border: none;
}

.chip-input-new::-webkit-input-placeholder{
  color: var(--text-color);
  font-size: small;
  opacity: 1;
}

.chip-input-new::-webkit-outer-spin-button,
.chip-input-new::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.chip-input::-webkit-outer-spin-button,
.chip-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.chip-text {
  font-size: 12px;
  font-weight: 700;
}

.chip-text-hilite {
  font-size: 16px;
  font-weight: 700;
}

.chip-text-input {
  font-size: 1.2rem;
  font-weight: 700;
}

.chip-label {
  font-size: 1.25rem;
  font-weight: 800;
}

.container {
  padding: 10px 15px;
  height: 524px;
  align-items: center;
  justify-content: center;
  display:flex ;
}

.container-inner {
  min-height: 300px;
}

.container h3 {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--text-color);
}

.container h4 {
  font-size: 1rem;
  font-weight: 800;
  color: var(--text-color);
}

.container h5 {
  font-size: 0.75rem;
  font-weight: 700;
  color: var(--text-color); 
}

.container h6 {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--text-color);
}

.container p{
  font-size: 0.7rem;
  font-weight: 600;
  color: var(--text-color);
}

.card {
  width: 600px;
  padding: 15px;
  background-color: var(--bg-color);
  box-shadow: var(--box-shadow);
  border-radius: 24px;
  text-align: center;
}

.timer-card {
  width: 125px;
  height: 125px;
  padding: 5px;
  box-shadow: var(--box-shadow-hover);
  border-radius: 50%;
}

.timer-inner {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  color: var(--text-color);
}

.timer-text {
  margin: 0 auto;
}


.card h3{
  margin: 0 auto;
}

.card h4{
  margin-right: 15px;
}

.defi-toggle {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 200; /* Full-width */
  height: 25px; /* Specified height */
  border-radius: 25px;
  box-shadow: var(--box-shadow-inset-strong);
  background: var(--bg-color); /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: ease-in-out .2s;
}

/* Mouse-over effects */
.slider:hover {
  box-shadow: var(--box-shadow-inset);
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 35px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  border-radius: 50%;
  box-shadow: var(--box-shadow-hover);
  background: var(--text-secondary); /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 35px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  box-shadow: var(--box-shadow-hover);
  background: var(--bg-color); /* Green background */
  border-radius: 50%;
  cursor: pointer; /* Cursor on hover */
}

@media screen and (max-width: 780px) {
  .topBar-links {
    display: none;
  }

  .bottom-bar {
    display: flex;
  }
  
  .divider-bottom {
    display: flex;
  }

  .card {
    width: 450px;
  }
}

.clock-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='112' ry='112' stroke='grey' stroke-width='18' stroke-dasharray='1%2c4.5' stroke-dashoffset='25' stroke-linecap='butt'/%3e%3c/svg%3e") !important;
}

.clock-dashed--animating {
  animation: dash-animate 10s linear infinite;
}

@keyframes dash-animate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}